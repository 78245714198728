/* eslint-disable no-undef */

// @ts-ignore
require('./src/css/style.css');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { initGA } = require('@websites/utils');

exports.onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    initGA();
  }
};
